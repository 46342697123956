import { push } from 'connected-react-router';
import { t } from 'i18next';
import * as i18n from 'i18next';
import moment from 'moment';
import * as R from 'ramda';
import React, { useState, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { companyActions } from '../../../../modules/company';
import {
  fetchCompaniesByAdmin,
  fetchDeletedCompanies,
  clearCompanies,
  removeCompanyFully
} from '../../../../modules/company/companyActions';
import { modalsActions, modalsConstants } from '../../../../modules/modals';
import { MODAL_TYPES } from '../../../../modules/modals/modalsConstants';
import { ITEMS_PER_PAGE } from "../../../constants/pagination";
import { routes } from '../../../routes/routesConfig';
import { IconBtnS, Pagination, SelectItemsPerPage, Table } from '../../common';
import { 
  TableTextSmallS, 
  TableTextLowercaseS, 
  TableTitleNameS, 
  TableIconsWrapS, 
} from "../../common/table/TableS";
import ConfirmationModal from '../../modals/confirmation';
import { PageTitleVioletS } from '../adminLayout/adminCommonStyles';

import {
  NavTabs,
  NavTabsBtn,
  NavWrapS,
  TotalPages,
  TotalPagesCount
} from './companiesS';
import  PlanTierButtonsComponent  from './PlanTierButtonsComponent';

const ActionIcons = ({ isCompanyInactive, companyId }) => {
  const dispatch = useDispatch();

  const setModalStateFor = (modalType, payload) => {
    dispatch(modalsActions.setModalStateFor(modalType, payload));
  };

  const updateCompanyStatus = (status, id) => {
    dispatch(companyActions.updateCompanyStatus(status, id));
  };

  return (
    <TableIconsWrapS>
      {
        isCompanyInactive ? (
          <IconBtnS
            iconName="redo"
            color="green"
            bgColorHover="greenAlpha"
            title={i18n.t('dashBoard.buttons.deleteTooltip')}
            onClick={() => updateCompanyStatus('active', companyId)}
          />
        ) : (
          <Link to={`/admin/company-profile/${companyId}`}>
            <IconBtnS
              iconName="edit"
              color="greyYellow"
              bgColorHover="tulipTreeAlpha"
              title={i18n.t('dashBoard.buttons.editTooltip')}
            />
          </Link>
        )
      }
      <IconBtnS
        iconName="trash-alt"
        color="red"
        bgColorHover="redAlpha"
        title={i18n.t('dashBoard.buttons.deleteTooltip')}
        onClick={
          isCompanyInactive
            ? () => setModalStateFor(
              modalsConstants.MODAL_TYPES.COMPANY_DELETE_CONFIRMATION,
              { open: true, companyId }
            )
            : () => updateCompanyStatus('inactive', companyId)
        }
      />
    </TableIconsWrapS>
  )
};

const sortInitialState = { name: 0, createdAt: 0 };

export const COMPANY_STATE = {
  ACTIVE: 'active',
  DELETED: 'deleted',
};

const tabsBtn = [
  { filterName: COMPANY_STATE.ACTIVE, textKey: 'adminPanel.companies.buttons.activeCompany' },
  { filterName: COMPANY_STATE.DELETED, textKey: 'adminPanel.companies.buttons.deleteCompany' }
];


const CompaniesComponent = () => {
  const dispatch = useDispatch();
  const amount = useSelector(state => state.company.amount);
  const location = useSelector(state => state.router.location);
  
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE[10]);
  const [companiesStateType, setCompaniesStateType] = useState(COMPANY_STATE.ACTIVE);
  const [sort, setSort] = useState(sortInitialState);

  const normalizeCompanyList = companies => companies.map(company => {
    const name = R.pathOr('-', ['name'], company);
    const location = R.pathOr('-', ['base', 'address', 'city'], company);
    const phone = R.pathOr('-', ['base', 'tel'], company);
    const email = R.pathOr('-', ['base', 'email'], company);

    const hasCreatedAt = Boolean(company.createdAt);

    return {
        name: <TableTitleNameS title={name}>{ name }</TableTitleNameS>,
        data: <TableTextSmallS>
                {hasCreatedAt ? moment(company.createdAt).format('D MMM YYYY') : '-'}
              </TableTextSmallS>,
        location: <TableTextSmallS>{ location }</TableTextSmallS>,
        phone: <TableTextSmallS>{ phone }</TableTextSmallS>,
        email: <TableTextLowercaseS>{ email }</TableTextLowercaseS>,
        planTier: <PlanTierButtonsComponent 
                    planTier={company.planTier} 
                    companyId={company._id}
                    page={page} 
                    itemsPerPage={itemsPerPage} 
                    sort={sort}
                    companiesStateType={companiesStateType}
                    />,
        action: <ActionIcons isCompanyInactive={company.status === 'inactive'} companyId={company._id} />
    }
});

const companies = useSelector(state => normalizeCompanyList(state.company.list));

  const companyColumns = [
    {
      title: t('adminPanel.companies.tableHeaders.name'),
      sort: {
        sortFunc : sortOrder => setSort({ ...sortInitialState, name: sortOrder }),
        sortState: sort.name
      }
    },
    {
      title: t('adminPanel.companies.tableHeaders.date'),
      sort: {
        sortFunc : sortOrder => setSort({ ...sortInitialState, createdAt: sortOrder }),
        sortState: sort.createdAt
      }
    },
    { title: t('adminPanel.companies.tableHeaders.location') },
    { title: t('adminPanel.companies.tableHeaders.phone') },
    { title: t('adminPanel.companies.tableHeaders.email') },
    { title: t('adminPanel.companies.tableHeaders.planTier') },
    { title: t('adminPanel.companies.tableHeaders.action') }
  ];

  useEffect(() => {
    // if user make search from any other route and been redirected to companies do not make request.
    if (!R.path(['state', 'search'], location)) {
      if (companiesStateType === COMPANY_STATE.DELETED) {
        dispatch(fetchDeletedCompanies((page - 1), itemsPerPage));
      } else {
        dispatch(fetchCompaniesByAdmin((page - 1), itemsPerPage));
      }
      
    } else {
      dispatch(push(`${routes.ADMIN.BASE}${routes.ADMIN.COMPANIES}`, { search: false })); // should reset search state.
    }
  }, [location, page, itemsPerPage]);

  const prevCompaniesStateTypeRef = useRef(companiesStateType);
  const prevSortRef = useRef(sort);
  const prevPageRef = useRef(page);
  const prevItemsPerPageRef = useRef(itemsPerPage);

  useEffect(() => {

    if (
      prevCompaniesStateTypeRef.current !== companiesStateType ||
      prevSortRef.current !== sort
    ) {
      //  clear some data in case user change tab
      setPage(1);
      dispatch(clearCompanies());
    }

    if (
      prevCompaniesStateTypeRef.current !== companiesStateType ||
      prevSortRef.current !== sort ||
      prevPageRef.current !== page ||
      prevItemsPerPageRef.current !== itemsPerPage
    ) {
      const getSortValue = sort => R.pickBy(val => val !== 0, sort);
      if (companiesStateType === COMPANY_STATE.DELETED) {
        dispatch(fetchDeletedCompanies((page - 1), itemsPerPage, getSortValue(sort)));
      } else {
        dispatch(fetchCompaniesByAdmin((page - 1), itemsPerPage, getSortValue(sort)));
      }
    }

    prevCompaniesStateTypeRef.current = companiesStateType;
    prevSortRef.current = sort;
    prevPageRef.current = page;
    prevItemsPerPageRef.current = itemsPerPage;

  }, [itemsPerPage, page, companiesStateType, sort]);  

  return (
    <>
      <PageTitleVioletS>
        <Trans i18nKey="adminPanel.companies.companyProfile"/>
      </PageTitleVioletS>

      <ConfirmationModal
        confirmationName={MODAL_TYPES.COMPANY_DELETE_CONFIRMATION}
        onApproved={(companyId) => removeCompanyFully(companyId)}
      />

      <NavTabs aItems="center">
        {tabsBtn.map(({ filterName, textKey }) => (
          <NavTabsBtn
            key={filterName}
            fz="mediumText"
            padding="6px 15px"
            fontFamily="normal"
            onClick={() => {
              setCompaniesStateType(filterName);
              push(location.pathname, { companiesType: filterName });
            }}
            active={filterName === companiesStateType}
          >
            <Trans i18nKey={textKey}/>
          </NavTabsBtn>
        ))}
      </NavTabs>

      <Table
        columns={companyColumns}
        data={companies}
      />

      <NavWrapS aItems="center" justify="space-between">

        <SelectItemsPerPage
          options={Object.values(ITEMS_PER_PAGE)}
          itemsPerPage={itemsPerPage}
          onChangeItemsPerPage={changedTo => setItemsPerPage(changedTo)}
        />

        <Pagination
          itemsPerPage={itemsPerPage}
          itemsAmount={amount}
          pageToRender={page}
          setPage={setPage}
        />

        <TotalPages>
          <Trans i18nKey="adminPanel.companies.totalCompany"/>
          <TotalPagesCount>{amount}</TotalPagesCount>
        </TotalPages>
      </NavWrapS>
    </>
  );
};

export default CompaniesComponent;
