import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { appReducer } from '../modules/app';
import { authReducer, } from '../modules/auth';
import { companyReducer } from '../modules/company';
import { companyFieldsScoresReducer } from '../modules/companyFieldsScores';
import { companyFilterReducer } from '../modules/companyFilter';
import { modalsReducer } from '../modules/modals';
import { profileReducer } from '../modules/profile';
import { reviewReducer } from '../modules/review';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  app: appReducer,
  company: companyReducer,
  companyFilter: companyFilterReducer,
  review: reviewReducer,
  profile: profileReducer,
  modals: modalsReducer,
  companyFieldsScores: companyFieldsScoresReducer,
});
