import {
  FETCH_COMPANY_FIELDS_SCORES_REQUEST,
  FETCH_COMPANY_FIELDS_SCORES_SUCCESS,
  FETCH_COMPANY_FIELDS_SCORES_ERROR,
  UPDATE_COMPANY_FIELDS_SCORES_REQUEST,
  UPDATE_COMPANY_FIELDS_SCORES_ERROR,
  UPDATE_COMPANY_FIELDS_SCORES_SUCCESS,
} from './companyFieldsScoresConstants';

const initialState = {
  scores: {
    logo: 0,
    canton: 0,
    baseAddressCity: 0,
    baseAddressStreet: 0,
    baseAddressZip: 0,
    baseTel: 0,
    yearOfFoundation: 0,
    workingHours: 0,
    communicationPreferences: 0,
    info: 0,
    baseWebsite: 0,
    rateSecretary: 0,
    rateAccountant: 0,
    rateAuditor: 0,
    rateExpert: 0,
    employees: 0,
    servicesSpecialisation: 0,
    industries: 0,
    languages: 0,
    member: 0,
    software: 0,
    pictures: 0,
    employeeCount: 0,
    premiumScore: 0,
    premiumPlusScore: 0,
    matchingCriteriaCantonZipCity: 0,
    matchingCriteriaHourlyRate: 0,
    matchingCriteriaSoftware: 0,
    matchingCriteriaCommunicationPreferences: 0,
    matchingCriteriaAccountingBasis: 0,
    matchingCriteriaSpecialisation: 0,
    matchingCriteriaIndustries: 0,
    matchingCriteriaSpecialOffer: 0,
  },
  loading: false,
  error: null,
  entityId: null,
};

export default function companyFieldsScoresReducer(state = initialState, action) {
  const { error, type, payload } = action;

  switch (type) {
    case FETCH_COMPANY_FIELDS_SCORES_REQUEST:
    case UPDATE_COMPANY_FIELDS_SCORES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_COMPANY_FIELDS_SCORES_SUCCESS: {
      const { _id, updatedAt, ...rest } = payload;
      return {
        ...state,
        scores: rest,
        entityId: _id,
        loading: false,
      };
    }
    case UPDATE_COMPANY_FIELDS_SCORES_SUCCESS:
      return {
        ...state,
        scores: payload,
        loading: false,
      };
    case FETCH_COMPANY_FIELDS_SCORES_ERROR:
    case UPDATE_COMPANY_FIELDS_SCORES_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case UPDATE_COMPANY_FIELDS_SCORES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
