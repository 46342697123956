import { appName } from '../../config';

export const moduleName = 'companyFieldsScores';
const prefix = `${appName}/${moduleName}`;

export const FETCH_COMPANY_FIELDS_SCORES_REQUEST = `${prefix}/FETCH_COMPANY_FIELDS_SCORES_REQUEST`;
export const FETCH_COMPANY_FIELDS_SCORES_SUCCESS = `${prefix}/FETCH_COMPANY_FIELDS_SCORES_SUCCESS`;
export const FETCH_COMPANY_FIELDS_SCORES_ERROR = `${prefix}/FETCH_COMPANY_FIELDS_SCORES_ERROR`;

export const UPDATE_COMPANY_FIELDS_SCORES_REQUEST = `${prefix}/UPDATE_COMPANY_FIELDS_SCORES_REQUEST`;
export const UPDATE_COMPANY_FIELDS_SCORES_SUCCESS = `${prefix}/UPDATE_COMPANY_FIELDS_SCORES_SUCCESS`;
export const UPDATE_COMPANY_FIELDS_SCORES_ERROR = `${prefix}/UPDATE_COMPANY_FIELDS_SCORES_ERROR`;
