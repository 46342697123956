import i18next from 'i18next'
import * as R from 'ramda'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import { FieldError, PrimaryButtonS, TextInput } from '../../common'

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`
const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t('forms.common.emailForm.errors.isNotValid'))
    .required(i18next.t('forms.common.emailForm.errors.required')),
  companyName: Yup.string()
    .required(i18next.t('forms.common.companyName.errors.required')),
  phoneNumber: Yup.string()
    .required(i18next.t('forms.common.phoneNumber.errors.required'))
})

const DuplicationCompanyForm = ({ submitHandler, serverError }) => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({ companyName: '', email: '', phoneNumber: '' })
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onChange = e => setValues({ ...values, [e.target.name]: e.target.value })
  const validate = async data => {
    try {
      await schema.validate(data, { abortEarly: false })
      setErrors({})
      return true
    } catch (ex) {
      let all = {}
      ex.inner.forEach(({ path, message }) => { all[path] = message })
      setErrors(all)
      return false
    }
  }
  const onBlur = async e => {
    setTouched({ ...touched, [e.target.name]: true })
    await validate(values)
  }
  const onSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)
    if (!(await validate(values))) {
      setIsSubmitting(false)
      return
    }
    submitHandler(values, dispatch)
    setValues({ companyName: '', email: '', phoneNumber: '' })
    setTouched({})
    setIsSubmitting(false)
  }
  return (
    <Form onSubmit={onSubmit}>
      <FieldError error={serverError && i18next.t(`general.serverErrorsByCodes.${serverError.messageCode}`)} />
      <InputWrapper>
        <TextInput
          name="companyName"
          type="text"
          placeholder={i18next.t('forms.common.companyName.placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          value={R.prop('companyName', values)}
          isError={R.prop('companyName', errors) && R.prop('companyName', touched)}
          error={errors.companyName}
        />
      </InputWrapper>
      <InputWrapper>
        <TextInput
          name="email"
          type="text"
          placeholder={i18next.t('forms.common.emailForm.placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          value={R.prop('email', values)}
          isError={R.prop('email', errors) && R.prop('email', touched)}
          error={errors.email}
        />
      </InputWrapper>
      <InputWrapper showEmptyField>
        <InputMask
          mask="\099 999 99 99"
          maskChar="X"
          value={R.prop('phoneNumber', values)}
          onChange={onChange}
          onBlur={onBlur}
        >
          {props => (
            <TextInput
              name="phoneNumber"
              type="text"
              placeholder={i18next.t('forms.common.phoneNumber.placeholder')}
              isError={R.prop('phoneNumber', errors) && R.prop('phoneNumber', touched)}
              error={errors.phoneNumber}
              {...props}
            />
          )}
        </InputMask>
      </InputWrapper>
      <PrimaryButtonS disabled={isSubmitting} type="submit">
        {i18next.t('forms.common.emailForm.submitBtn')}
      </PrimaryButtonS>
    </Form>
  )
}

export default DuplicationCompanyForm
