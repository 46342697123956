import {
  FETCH_COMPANY_FIELDS_SCORES_REQUEST,
  FETCH_COMPANY_FIELDS_SCORES_SUCCESS,
  FETCH_COMPANY_FIELDS_SCORES_ERROR,
  UPDATE_COMPANY_FIELDS_SCORES_REQUEST,
} from './companyFieldsScoresConstants';

export const fetchCompanyFieldsScores = (payload) => ({
  type: FETCH_COMPANY_FIELDS_SCORES_REQUEST,
  payload,
});

export const fetchCompanyFieldsScoresSuccess = (payload) => ({
  type: FETCH_COMPANY_FIELDS_SCORES_SUCCESS,
  payload,
});

export const fetchCompanyFieldsScoresFailure = (error) => ({
  type: FETCH_COMPANY_FIELDS_SCORES_ERROR,
  payload: error,
});

export const updateCompanyFieldsScores = (payload) => ({
  type: UPDATE_COMPANY_FIELDS_SCORES_REQUEST,
  payload,
});
