import { call, put, all, takeEvery, select } from 'redux-saga/effects';

import { get, post, urls } from '../../utils/api';

import {
  FETCH_COMPANY_FIELDS_SCORES_REQUEST,
  FETCH_COMPANY_FIELDS_SCORES_SUCCESS,
  FETCH_COMPANY_FIELDS_SCORES_ERROR,
  UPDATE_COMPANY_FIELDS_SCORES_REQUEST,
  UPDATE_COMPANY_FIELDS_SCORES_SUCCESS,
  UPDATE_COMPANY_FIELDS_SCORES_ERROR,
} from './companyFieldsScoresConstants';
import { showErrorNotification, showSuccessNotification } from '../app/appActions';
import { t } from 'i18next';

function* fetchCompanyFieldsScoresSaga() {
  try {
    const { data } = yield call(get, urls.companyFieldsScores);
    yield put({ type: FETCH_COMPANY_FIELDS_SCORES_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: FETCH_COMPANY_FIELDS_SCORES_ERROR, error: err.data || err });
  }
}

function* updateCompanyFieldsScoresSaga({ payload }) {
  const { entityId } = yield select(({ companyFieldsScores }) => companyFieldsScores);
  try {
    const { results } = yield call(post, urls.updateCompanyFieldsScores, {
      entities: [
        {
          id: entityId,
          data: payload,
        },
      ],
    });
    const { status, error } = results[0] || {};
    if (status === 'updated') {
      yield put({ type: UPDATE_COMPANY_FIELDS_SCORES_SUCCESS, payload });
      yield put(showSuccessNotification({ text: t('adminPanel.dashbord.notificationBar.success') }));
    }
    if (error) throw error;
  } catch (err) {
    yield put({ type: UPDATE_COMPANY_FIELDS_SCORES_ERROR, error: err.data || err });
    yield put(showErrorNotification({ text: t('adminPanel.dashbord.notificationBar.error') }));
  }
}

export default function* companyFieldsScoresSaga() {
  yield all([
    takeEvery(FETCH_COMPANY_FIELDS_SCORES_REQUEST, fetchCompanyFieldsScoresSaga),
    takeEvery(UPDATE_COMPANY_FIELDS_SCORES_REQUEST, updateCompanyFieldsScoresSaga),
  ]);
}
